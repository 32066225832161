import React, { useState, Fragment, useRef, useEffect } from 'react';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import moment from 'moment';
import { isEmpty, remove } from 'lodash';

import { addOperators } from './AdvancedSearchUtil';

import './AdvancedSearch.css';

import FormBuilder from "../Form/FormBuilder";
import ModalWindow from '../ModalWindow/ModalWindow';
import TabBuilder from '../TabBuilder/TabBuilder';
import ClinicusComboBox from '../ClinicusGrid/ClinicusComboBox';

const AdvancedSearch = (props) => {
    const { advancedSearchCriterias } = props;

    const [selectedTab, setSelectedTab] = useState(1);
    const [columnOptions, setColumnOptions] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState({});
    const [operatorOptions, setOperatorOptions] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState({});
    const [selectedValueType, setSelectedValueType] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const [disableValueField, setDisableValueField] = useState(false);
    const [searchCriterias, setSearchCriterias] = useState([]);
    const [andOrCriteria, setAndOrCriteria] = useState("or");

    // const [isSelectOption, setIsSelectOption] = useState(false);
    // const [initialValue, setInitialValue] = useState({});
    // const [startDate, setStartDate] = useState(null);
    const leftQBuilderForm = useRef();

    useEffect(() => {
        const { columns } = props;
        const optionList = columns;

        const columnsWithOperators = addOperators(optionList);
        setColumnOptions(columnsWithOperators);
        setSearchCriterias(advancedSearchCriterias?.searchBuilder || []);
        setAndOrCriteria(advancedSearchCriterias?.conditionType || "or");
    }, []);

    const onChangeColumn = (event, selectedOptionObj) => {
        setSelectedColumn(selectedOptionObj);
        setOperatorOptions(selectedOptionObj ? selectedOptionObj.operators : []);
        setSelectedValueType(selectedOptionObj ? selectedOptionObj.type : 'text');
        // setIsSelectOption((selectedOptionObj && selectedOptionObj.type === 'select') ? true : false);
    }

    const onChangeOperator = (event, selectedOperatorObj) => {
        let valueType = 'text';
        let valueFieldDisable = false;

        if (selectedOperatorObj && selectedOperatorObj.value === 'in') {
            valueType = 'textarea';
        } else if (selectedOperatorObj && selectedOperatorObj.value === 'between') {
            valueType = 'between';
        } else if (selectedOperatorObj && selectedOperatorObj.value === 'dateBetween') {
            valueType = 'dateBetween';
        } else if (selectedOperatorObj && (selectedOperatorObj.value === 'exists' || selectedOperatorObj.value === 'notExists')) {
            valueFieldDisable = true;
        }

        setSelectedOperator(selectedOperatorObj);
        setSelectedValueType(valueType);
        setDisableValueField(valueFieldDisable);
    }

    const onChangeAndCriteria = (e) => {
        setAndOrCriteria("and");
    }

    const onChangeOrCriteria = (e) => {
        setAndOrCriteria("or");
    }

    // Validates LeftSide Query Builder value field
    const onClickValidateFormFields = (operatorValue) => {
        let validationError = {};
        const formValues = leftQBuilderForm.current.validateFormAndGetValues();
        
        if (!['between', 'dateBetween'].includes(operatorValue) && !formValues["valueField"]) {
            validationError["valueField"] = "Value is Required";
        } else if (!formValues["firstDate"] && operatorValue === 'between') {
            validationError["firstDate"] = "Value is Required";
        } else if (!formValues["lastDate"] && operatorValue === 'between') {
            validationError["firstDate"] = "Value is Required";
        }

        setValidationErrors(validationError);
        return validationError;
    }

//   // Set Left Side Query Builder value on the Basic of operator type and Column Type
    const setLeftQueryFormValue = (columnType, operatorValue) => {
        let value = '';

        if (columnType === 'date' && operatorValue !== 'dateBetween') {
            const formValues = leftQBuilderForm.current.validateFormAndGetValues();
            value = formValues["valueField"];
            value = moment(value).format('YYYY-MM-DD');
        } else if (columnType !== 'date' && operatorValue.includes('exists', 'notExists')) {
            value = operatorValue;
        } else if (columnType === 'date' && operatorValue === 'dateBetween') {
            const formValues = leftQBuilderForm.current.validateFormAndGetValues();
            const firstDate = moment(formValues["firstDate"]).format('YYYY-MM-DD');
            const lastDate = moment(formValues["lastDate"]).format('YYYY-MM-DD');
            value = firstDate + ' to ' + lastDate;
        } else {
            const formValues = leftQBuilderForm.current.validateFormAndGetValues();
            value = formValues["valueField"];
        }

        const selectedValue = { label: value, value: value };

        return selectedValue;
    }

    const setOperatorEmpty = () => {
        setSelectedOperator({ label: '', value: '' });
    }

    const onClickResetCriteria = () => {
        const operatorValue = selectedOperator.value;

        setSelectedColumn({});
        setSelectedOperator((operatorValue && !operatorValue.includes('exists', 'notExists')) ? {} : { value: operatorValue, label: operatorValue });
        // setInitialValue({});
        setDisableValueField(false);
        // setStartDate('');
        
        if (operatorValue && !operatorValue.includes('exists', 'notExists')) {
            leftQBuilderForm.current.emptyFormValues({})
        } else {
            setOperatorEmpty();
        }
    }

    const onClickAddCriteria = () => {
        let rawSearchCriteria = {}, isFormValid = {};
        const operatorValue = selectedOperator.value;
        
        if (!operatorValue.includes('exists', 'notExists')) {
            isFormValid = onClickValidateFormFields(operatorValue);
        }

        if (Object.getOwnPropertyNames(isFormValid).length === 0) {
            const columnType = selectedColumn.type;
            const selectedValue = setLeftQueryFormValue(columnType, operatorValue);
            // if (!operatorValue.includes('exists', 'not_exists')) {
            //   leftQBuilderForm = this.refs.leftQBuilderForm;
            // }
            if (selectedColumn.label && selectedOperator.label) {
                rawSearchCriteria = {
                    selectedColumn,
                    selectedOperator,
                    selectedValue
                };
            }

            let newSearchCriterias = [...searchCriterias, rawSearchCriteria];
            newSearchCriterias = remove(newSearchCriterias, (criteria) => {
                return !isEmpty(criteria); 
            });
            
            setSearchCriterias(newSearchCriterias);
            setSelectedColumn({});
            setSelectedOperator((!operatorValue.includes('exists', 'notExists')) ? {} : { value: operatorValue, label: operatorValue });
            // setStartDate('');
            // setInitialValue({});

            if ((!operatorValue.includes('exists', 'notExists'))) {
                leftQBuilderForm.current.emptyFormValues({})
            } else {
                setOperatorEmpty();
            }
        }
    }

    const setDateInitialVal = (val) => {
        const date = (moment(val).toDate());

        return moment(date).format("YYYY-MM-DD");
    }

    const onClickEditCriteria = (idx, criteria) => {
        let value = '';
        let initialValues = {};

        if (criteria.selectedColumn.type === 'date' && criteria.selectedOperator.value !== 'dateBetween') {
            value = setDateInitialVal(criteria.selectedValue.value);
            initialValues = {
                "valueField": value
            };
        } else if (criteria.selectedColumn.type === 'date' && criteria.selectedOperator.value === 'dateBetween') {
            const splitedDate = (criteria.selectedValue.value).split('to');
            const firstDate = setDateInitialVal(splitedDate[0]);
            const lastDate = setDateInitialVal(splitedDate[1]);
            initialValues = {
                "firstDate": firstDate,
                "lastDate": lastDate
            };
        } else {
            value = criteria.selectedValue.value;
            initialValues = {
                "valueField": value
            };
        }

        setSelectedColumn(criteria.selectedColumn);
        setOperatorOptions(criteria.selectedColumn.operators);
        setSelectedOperator(criteria.selectedOperator);
        // setInitialValue(initialValues);
    }

    const onClickDeleteCriteria = (idx, criteria) => {
        setSearchCriterias(searchCriterias.filter((el, elidx) => elidx !== idx));
    }

    const onClickSearch = () => {
        const conditionType = andOrCriteria;
        const searchBuilder = [];
        searchCriterias.map((data) => {
            searchBuilder.push({
                selectedColumn: data.selectedColumn,
                selectedOperator: data.selectedOperator,
                selectedValue: { value: data.selectedValue.value != undefined ? data.selectedValue.value.trim() : '' }
            });
        });

        props.addAdvancedSearchCriterias({ conditionType, searchBuilder });
        // setAdvancedSearchCriterias(searchCriterias);
        /**Close the advance search modal window after clicking on search  */
        props.hideAdvancedSearch();
    }

    /** Build Left Side query Value field according to the operator selected */
    const buildSection = () => {
        const { options } = selectedColumn || {};
        let { type } = selectedColumn;
        let valueFields = [];

        if (selectedValueType === 'textarea') {
            type = selectedValueType;
            valueFields = [{
                type: type,
                name: 'valueField',
                options: options
            }];
        } else if (selectedValueType === 'dateBetween') {
            valueFields = [{
                type: type || 'text',
                name: 'firstDate',
                options: options
            }, {
                type: type || 'text',
                name: 'lastDate',
                options: options
            }];
        } else if (type === 'string') {
            valueFields = [{
                type: 'text',
                name: 'valueField',
                options: options
            }];
        }
        else {
            valueFields = [{
                type: type || 'text',
                name: 'valueField',
                options: options
            }];
        }

        return (
            <FormBuilder
                ref={leftQBuilderForm}
                fields={valueFields}
                // initialValues={initialValue}
                validationErrors={validationErrors}
                cols={1}
            />
        );
    }

    const handleTabSelect = eventKey => {
        setSelectedTab(eventKey);
    };

    const renderAdvancedSearchView = () => {
        return (
            <Fragment>
                <h5 className="subtitle">Select Search Criteria</h5>
                <div className="qpane-wrapper">
                    <div className="qpane-left">
                        <FormGroup>
                            <ClinicusComboBox
                                name="columns"
                                placeholder="Column"
                                value={selectedColumn}
                                options={columnOptions}
                                onChange={onChangeColumn}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ClinicusComboBox
                                name="operators"
                                placeholder="Operator"
                                value={selectedOperator}
                                options={operatorOptions}
                                onChange={onChangeOperator}
                            />
                        </FormGroup>
                        <FormGroup>
                            {!disableValueField && buildSection()}
                        </FormGroup>
                        <div>
                            <Button color="primary" onClick={onClickAddCriteria}>
                                <i className="fa fa-plus"></i> Add
                            </Button>{' '}
                            <Button color="danger" onClick={onClickResetCriteria}>
                                <i className="fa fa-refresh"></i> Reset
                            </Button>
                        </div>
                    </div>
                    <div className="qpane-right">
                        <section className="qpane-sc-header">
                            <span className="subtitle">Search for records that match</span>
                            <FormGroup tag="fieldset" style={{ display: 'inline', marginLeft: '20px' }}>
                                <FormGroup check style={{ display: 'inline' }}>
                                    <Label check>
                                        <Input type="radio" name="radioGroup" value="any_criteria" checked={andOrCriteria === "or"} onChange={onChangeOrCriteria} />{' '}
                                        Any Criteria
                                    </Label>
                                </FormGroup>{' '}
                                <FormGroup check style={{ display: 'inline' }}>
                                    <Label check>
                                        <Input type="radio" name="radioGroup" value="all_criteria" checked={andOrCriteria === "and"} onChange={onChangeAndCriteria} />{' '}
                                        All Criteria
                                    </Label>
                                </FormGroup>{' '}
                            </FormGroup>
                        </section>
                        <section className="qpane-sc-body">
                            {searchCriterias.length > 0 ?
                                searchCriterias.map((searchCriteria, idx) => {
                                    return (
                                        <div className="search-criteria" key={idx}>
                                            {searchCriteria && searchCriteria.selectedColumn ?
                                                <Fragment>
                                                    {searchCriteria.selectedColumn.label}{' '}
                                                    {searchCriteria.selectedOperator.label}{' '}
                                                    {searchCriteria.selectedValue.value}{' '}
                                                    <span className="pull-right sc-icons">
                                                        {/* <i className="fa fa-edit" onClick={() => onClickEditCriteria(idx, searchCriteria)} />{' '} */}
                                                        <i className="fa fa-trash cursor-pointer" onClick={() => onClickDeleteCriteria(idx, searchCriteria)} />
                                                    </span>
                                                </Fragment> : null
                                            }
                                        </div>
                                    )
                                }) : <div className="no-search-criteria">No search criteria is selected.</div>
                            }
                        </section>
                    </div>
                </div>
                <div className="text-right">
                    <Button color="primary" disabled={searchCriterias.length == 0} onClick={onClickSearch}>
                        <i className="fa fa-search-plus"></i> Search
                    </Button>{' '}
                    <Button color="danger" onClick={props.hideAdvancedSearch}>
                        <i className="fa fa-close"></i> Cancel
                    </Button>
                </div>
            </Fragment>
        );
    }

    const renderForm = () => {
        const tabs = [{
            title: 'Advanced Search View',
            component: renderAdvancedSearchView()
        }];

        return (
            <div className="border-0">
                <div>
                    <TabBuilder tabs={tabs} onSelect={handleTabSelect} />
                </div>
            </div>
        );
    }

    return (
        <ModalWindow size={"xl"} title="Advanced Search" isOpen={props.isOpen} toggleModal={() => props.hideAdvancedSearch()} noFooter>
            {renderForm()}
        </ModalWindow>
    );
}

export default AdvancedSearch;
