import { createSlice } from "@reduxjs/toolkit";
import { isNull, isUndefined } from "lodash";

import { navItems } from "constants";
import { validatePermissions } from "permissionsList";

const navSlice = createSlice({
    name: "navItems",
    initialState: {
        navItems: [],
        selectedItem: -1,
        isSidebarOpen: false,
        isSidebarToggle: true,
        isSidebarCollapsed: true
    },
    reducers: {
        updateNavItems: (state, action) => {
            if(!isNull(action.payload.navItems) && !isUndefined(action.payload.navItems))
                state.navItems = action.payload.navItems;

            if(!isNull(action.payload.selectedItem) && !isUndefined(action.payload.selectedItem))
                state.selectedItem = action.payload.selectedItem;

            if(!isNull(action.payload.isSidebarOpen) && !isUndefined(action.payload.isSidebarOpen))
                state.isSidebarOpen = action.payload.isSidebarOpen;

            if(!isNull(action.payload.isSidebarCollapsed) && !isUndefined(action.payload.isSidebarCollapsed))
                state.isSidebarCollapsed = action.payload.isSidebarCollapsed;

            if(!isNull(action.payload.isSidebarToggle) && !isUndefined(action.payload.isSidebarToggle))
                state.isSidebarToggle = action.payload.isSidebarToggle;
        },
        logoutNav: (state) => {
            state.navItems = [];
            state.selectedItem = -1;
            state.isSidebarOpen = false;
            state.isSidebarToggle = true;
            state.isSidebarCollapsed = true;
        }
    }
});

export const { updateNavItems, logoutNav } = navSlice.actions;
export const filterNavItems = (userPermissions) => (dispatch) => {
    const filteredNavItems = navItems.filter(item => validatePermissions(userPermissions, item.permissions));
    const navPath = window.location.pathname.split("/")[1];
    const pathIndex = filteredNavItems.findIndex(item => item.pathName === `/${navPath}`);
    const selectedItem = pathIndex === -1 ? 0 : pathIndex;

    dispatch(updateNavItems({ navItems: filteredNavItems, selectedItem, isSidebarOpen: false }));
};
export default navSlice.reducer;
