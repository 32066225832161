import { useEffect, useState } from "react";

const ScioCheckbox = ({ name, checked, onChange, disabled = false }) => {
	const [isChecked, setIsChecked] = useState(checked || false);

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

    const handleChange = (value) => {
		if(disabled)	return;

		setIsChecked(value);
        onChange && onChange(value);
    }

	return (
		<div>
			<input
				type="checkbox"
				name={name}
				checked={isChecked || false}
				onChange={(e) => handleChange(e.target.checked)}
				className="sr-only" // Visually hidden but accessible
			/>
			<div
				className={`w-4 h-4 rounded flex items-center justify-center cursor-pointer transition-colors duration-200
				ease-in-out ${isChecked ? (disabled ? 'bg-gray-400' : 'bg-[#0e5ebc]') : `${disabled ? "bg-gray-200" : "bg-white"} border border-[#d8d8d8]`}`}
				onClick={() => handleChange(!isChecked)}
			>
				{isChecked && (
					<svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 13l4 4L19 7" />
					</svg>
				)}
			</div>
		</div>
	);
}

export default ScioCheckbox;
