import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';

import { dropdownConfigs, textConfigs } from 'configs/appConfigs';

const ClinicusDropdown = ({ isOpen, toggle, dropdownClassName = "", displayComponentClassName = "", options = [], onClickOption, displayText = null, size = "md", hideDropdownIcon = false, fontSize="text-base", customStyle = "" }) => {
    return (
        // <Dropdown isOpen={isOpen} toggle={toggle} className={`${dropdownClassName}`}>
        //     <DropdownToggle size={size} className={`${displayComponentClassName ? displayComponentClassName : dropdownConfigs.colors.defaults.bgClassName} ${textConfigs.colors.defaults.textColorClassName} font-semibold border-[#CCD7E4] border-[1px] border-solid flex justify-between items-center w-full`}>
        //         <span className="whitespace-nowrap overflow-hidden text-ellipsis">{displayText?.label || "No option selected"}</span> <ChevronDownIcon className="w-5 h-5" />
        //     </DropdownToggle>
        //     <DropdownMenu>
        //         {options.map(option => (
        //             <DropdownItem className="hover:bg-[#c6cfff]" key={option.value} onClick={() => onClickOption(option)}>
        //                 {option.label}
        //             </DropdownItem>
        //         ))}
        //     </DropdownMenu>
        // </Dropdown>
        <UncontrolledDropdown className={`${dropdownClassName}`}>
            <DropdownToggle size={size} className={`${displayComponentClassName ? displayComponentClassName : dropdownConfigs.colors.defaults.bgClassName} ${textConfigs.colors.defaults.textColorClassName} font-semibold border-none flex justify-between items-center w-full ${customStyle}`}>
                <span className="whitespace-nowrap overflow-hidden text-ellipsis">{displayText?.label || "No option selected"}</span> {hideDropdownIcon ? "" : <ChevronDownIcon className="ml-2 w-5 h-5" />}
            </DropdownToggle>
            <DropdownMenu className='w-max'>
                {options.map(option => (
                    <DropdownItem className={`hover:bg-[#CCD7E4] w-full ${fontSize} text-pretty ${displayText?.label === option.label ? 'bg-[#E5EFFB]' : ''}`} key={option.value} onClick={() => onClickOption(option)}>
                        {option.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default ClinicusDropdown;
