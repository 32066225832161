import { PresentationChartBarIcon } from "@heroicons/react/24/solid";
import _ from "lodash";

import { navItemsIconMapping } from "./constants";

export const getNavItemIcon = (itemName) => {
    let icon = navItemsIconMapping[itemName];

    if(!icon)
        icon = <PresentationChartBarIcon className="h-5 w-5" />;

    return icon;
};

export const labelStrConversion = (input) => {
    const camelPascalCasePattern = /^(?:[A-Z][a-z]*|\d+)+$/;
    const formattedStr = camelPascalCasePattern.test(input) ? _.startCase(input) : input;
    return formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1)
};
