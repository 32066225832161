import React, { useEffect, useMemo } from 'react';
import { Navigate, Route, Routes as Switch, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { map } from 'lodash';

import './assets/tailwindcss/tailwind.css';

import { permissions } from './permissionsList';

import withAuthorization from './common/HOC/withAuthorization';

import Header from './loggedinComponents/Header/Header';
import Sidebar from './loggedinComponents/Sidebar/Sidebar';
import NotFound from './common/NotFound/NotFound';
import ContentBox from './common/Box/ContentBox';
import SearchResults from 'loggedinComponents/Header/SearchResults';
import Impersonate from 'loggedinComponents/Impersonate/Impersonate';

const PatientsRouter = React.lazy(() => import('./loggedinComponents/Patients/PatientsRouter'));
const HomepageRouter = React.lazy(() => import('./loggedinComponents/Homepage/HomepageRouter'));
const TcmRouter = React.lazy(() => import('./loggedinComponents/TcmDashboard/TcmRouter'));

const RedirectAfterLogin = () => {
    const navigate = useNavigate();
    const location = useLocation(); 
    const { user } = useSelector(store => store.userSlice);
    const { navItems, selectedItem } = useSelector(store => store.navSlice);

    useEffect(() => {
        if (navItems.length > 0) {
            const currentPath = location.pathname;
            const permittedPaths = map(navItems, "pathName") || [];
            permittedPaths.push("/results", "/not-found");

            if(user.permissions.includes(permissions.impersonate.impersonateUser)) {
                permittedPaths.push("/impersonate");
            }

            const isCurrentPathPermitted = navItems.some(item => currentPath.startsWith(item.pathName)) || [];

            if (!isCurrentPathPermitted) {
                navigate(navItems[selectedItem]?.pathName);
            }
        }
    }, [user?.email, navItems, location.pathname, selectedItem]);

    useEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [location.pathname]);

    return null;
}

const AppRoutes = () => {
    const AuthorizedHomepageRouter = useMemo(() => withAuthorization(HomepageRouter, permissions.homepage), []);
    const AuthorizedPatientsRouter = useMemo(() => withAuthorization(PatientsRouter, permissions.patients), []);
    const AuthorizedTcmRouter = useMemo(() => withAuthorization(TcmRouter, permissions.tcm), []);
    const AuthorizedImpersonate = useMemo(() => withAuthorization(Impersonate, permissions.impersonate), []);

    const { navItems, selectedItem } = useSelector(store => store.navSlice);

    return (
        <React.Suspense fallback={<div>Loading....</div>}>
            <ContentBox>
                <Switch>
                    <Route path='/homepage/*' element={<AuthorizedHomepageRouter />} />
                    <Route path='/patients/*' element={<AuthorizedPatientsRouter />} />
                    <Route path='/tcm-dashboard/*' element={<AuthorizedTcmRouter />} />
                    <Route path = '/results' element = {<SearchResults/>}/>
                    <Route path = '/impersonate' element = {<AuthorizedImpersonate/>}/>
                    <Route path='/not-found' element={<NotFound />} />
                    <Route path='*' element={<Navigate to={navItems[selectedItem]?.pathName} replace />} />
                </Switch>
            </ContentBox>
        </React.Suspense>
    );
}

const AuthorisedRoutes = () => {
    return (
            <React.Suspense fallback={<div>Loading....</div>}>
                {/* /Header */}
                <Header />

                <RedirectAfterLogin />

                <div className='flex'>
                    {/* Sidebar */}
                    <Sidebar />
                    {/* Routes */}
                    <AppRoutes />
                </div>
            </React.Suspense>
	);
}

export default AuthorisedRoutes;
