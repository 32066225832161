import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { textConfigs } from 'configs/appConfigs';

const Title = ({ iconElement = null, titleElement = null, subTitleElement = null, alignment = "left", redirectionUrl = "", fontColor = "text-black", fontSize = "text-xl", fontWeight = "font-bold", redirectionUrlTitleOnly = "" }) => {
    const titleClassNames = `${fontWeight} ${fontSize} flex flex-col w-max justify-center ${alignment === "right" ? "items-end" : ""} `;
    const subTitleClassNames = `font-normal text-sm`;

    return (
        <div className="flex gap-2 justify-start items-start">
            {iconElement ? <div className='mt-1'>
                {iconElement}
            </div> : null}

            {redirectionUrl ?
                <Link to={redirectionUrl} className={`${titleClassNames} ${textConfigs.colors.defaults.textLinkColorClassNames} underline`}>
                    {titleElement}
                    {subTitleElement ? <div className={subTitleClassNames}>{subTitleElement}</div> : null}
                </Link> :
                redirectionUrlTitleOnly ? <div className={titleClassNames}><Link to={redirectionUrlTitleOnly} className={`${titleClassNames} ${textConfigs.colors.defaults.textLinkColorClassNames} underline`}>
                    {titleElement}</Link>
                    {subTitleElement ? <div className={subTitleClassNames}>{subTitleElement}</div> : null}</div>
                    : <div className={titleClassNames + fontColor}>
                        {titleElement}
                        {subTitleElement ? <div className={subTitleClassNames}>{subTitleElement}</div> : null}
                    </div>
            }
        </div>
    );
}

Title.propTypes = {
    alignment: PropTypes.oneOf(['left', 'right'])
}

export default Title;
