import React, { useEffect, useState } from 'react';
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { Toaster } from 'react-hot-toast';
import { get } from 'lodash';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { toastConfigs } from 'configs/appConfigs';
import { getUserDetails } from 'store/userSlice';
import { toastNotify } from 'common/Utils/toastNotify';
import { AUTH_STATUS } from 'enums/authEnum';

import AuthorisedRoutes from './AuthorisedRoutes';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import SignIn from 'loggedoutComponents/SignIn';
import TinyUrlRedirect from 'redirectComponents/TinyUrlRedirect';
// import AmazonConnect from './amazon-connect/amazon-connect';

/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
 * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be 
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

const MainContent = () => {
    const { instance, accounts, inProgress } = useMsal();
    const navigate = useNavigate();
    const location = useLocation();
    const activeAccount = instance.getActiveAccount();
    const activeEmail = get(activeAccount, 'username', '');
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [redirected, setRedirected] = useState(false);
    const { status, error } = useSelector(store => store.userSlice);
    const dispatch = useDispatch();

    useEffect(() => {
        const handleRedirect = async () => {
            try {
                const response = await instance.handleRedirectPromise();
                if (response && response.state) {
                    navigate(response.state)
                } else if (accounts && accounts.length > 0 && location.pathname === "/") {
                    navigate('/homepage')
                }
            } catch (error) {
                console.log("error in handleRedirectPromise ", error);
            }
        };
        handleRedirect();


        if (!accounts || accounts.length === 0) {
            if (location.pathname !== "/sign-in") {
                navigate("/sign-in", { state: location.pathname });
            }
        } else if (accounts && accounts.length > 0 && !redirected) {
            if (activeEmail) {
                if (isInitialRender) {
                    authoriseUser();
                    setIsInitialRender(false);
                }
                setRedirected(true);
            } else {
                console.warn("Active email is undefined but user is logged in");
            }
        }
    }, [accounts, activeEmail, dispatch, inProgress, instance, redirected]);

    const authoriseUser = async () => {
        dispatch(getUserDetails({ email: activeEmail, isImpersonatedUser: false, actualUserEmail: "" }));
    };

    if (error?.message) {
        toastNotify("Error in fetching your details. Please try again later!", "error", "text-sm");
    }

    return (
        <div className="App">
            <Routes>
                <Route path="/sign-in" element={
                    <UnauthenticatedTemplate>
                        <SignIn />
                    </UnauthenticatedTemplate>
                } />

                <Route path="/*" element={  // All other routes
                    <AuthenticatedTemplate> {/* Authenticated routes */}
                        <Spin spinning={status === AUTH_STATUS.LOGGING_IN} tip="Loading your details..." className="h-screen">
                            {status === AUTH_STATUS.LOGGING_IN && <div>Loading...</div>}
                            {status === AUTH_STATUS.LOGGED_IN && <AuthorisedRoutes />}
                            {status === AUTH_STATUS.LOGGING_ERROR && <div className="grid place-content-center">Error loading your details...</div>}
                        </Spin>
                    </AuthenticatedTemplate>
                } />
            </Routes>
        </div>
    );
};

const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <BrowserRouter>
                <React.Suspense fallback={<div>Loading please wait...</div>}>
                    <MainContent />
                    <Toaster toastOptions={toastConfigs.defaults} />
                </React.Suspense>
            </BrowserRouter>
        </MsalProvider>
        // <div>
        //     <AmazonConnect/>
        // </div>
    );
}

export default App;
