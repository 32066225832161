import React from 'react';
import { useNavigate } from 'react-router';

import { textConfigs } from '../../configs/appConfigs';

const BackButton = ({ path = "/homepage", text = "Click me", className = "" }) => {
    const navigate = useNavigate();

    return (
        <div onClick={() => navigate(path)} data-path={path} className={`flex cursor-pointer w-max justify-start items-center no-underline gap-2 font-bold text-base
         ${textConfigs?.colors.defaults.textLinkColorClassNames} ${className}`}>
            <i className="clinicus-cheveron-outline-left text-2xl pt-0.5" /> {text}
        </div>
    );
}

export default BackButton;
